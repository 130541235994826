import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { Document, Page } from 'react-pdf';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

const pdfs = require.context('../../assets/pdfs/', false);

const notShareablePDFs = [
	'76479-Assert-IQ-Launch-Checklist1.pdf',
	'First-Implant-Social-Primers.pdf',
	'Module-Product-Differentiation-for-the-Assert-IQ-ICM.pdf',
	'Module-Why-What-Where-Strategically-Positioning-the-Assert-IQ-ICM.pdf',
	'Module-Constructing-Value-Propositions-for-the-Assert-IQ-ICM.pdf',
	'Module-Managing-Objections-of-the-Assert-IQ-ICM-System-Real-Examples.pdf',
	'76063-ICM-workflow-guide1.pdf',
	'ICM-Technical-Insight–LOC-Discriminator.pdf',
	'76760-Selling-with-Intention-Worksheet1.pdf',
	'76357-Indications-and-Intended-Use-Workshop1.pdf',
	'MRI-FAQ.pdf',


];

/* async function share(shareLink, shareTitle) {
	try {
		await navigator.share({ title: shareTitle, url: shareLink });
	} catch (err) {
		console.error('Share failed:', err.message);
		try {
			navigator.clipboard.writeText(shareLink);
			alert('Data was copied successfully');
		} catch (e) {
			alert(`Copy failed: ${e.message}`);
		}
	}
} */

const filesUrl = 'https://abbott-assets.s3.us-west-2.amazonaws.com/Assert/pdf/';

class PdfViewer extends Component {
	state = {
		numPages: 0
	};

	onDocumentLoadSuccess = ({ numPages }) => {
		this.setState({ numPages: numPages });
	};

	render() {
		const { location } = this.props;
		const { numPages } = this.state;
		console.log(location);
		const params = new URLSearchParams(this.props.location.search);
		const documentTitle = params.get('title');
		const documentFile = params.get('file');
		let pdfFile = pdfs('./' + documentFile);
		const pages = [];
		for (let p = 0; p < numPages; p++) {
			pages.push(
				<div key={`page${p}`} style={{ paddingTop: '5vh', paddingRight: '5w', paddingLeft: '5w' }}>
					<Page pageNumber={p + 1} />
				</div>
			);
		}
		return (
			<div
				style={{
					position: 'fixed',
					top: 0,
					height: '100vh',
					width: '100%',
					background: '#f5f5f5',
					zIndex: '200',
					maxWidth: 768,
					overflowX: 'hidden'
				}}
			>
				<div
					style={{
						position: 'absolute',
						background: 'white',
						width: '100%',
						height: '7vh',
						zIndex: 100,
						display: 'flex',
						alignItems: 'center',
						boxShadow: '0 0 2px lightgray',
						justifyContent: 'space-between',
						paddingRight: 15,
						paddingLeft: 15
					}}
				>
					<div
						style={{ fontSize: '1.5vh', color: 'gray', display: 'flex', width: '20vw' }}
						className="pointer "
						onClick={() => this.props.history.goBack()}
					>
						<i
							className="las la-times"
							style={{ fontSize: '2vh', color: '#470A68', marginRight: 5 }}
						/>CLOSE
					</div>
					<div style={{ textAlign: 'center', fontSize: '1.85vh', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '50vw', whiteSpace: 'nowrap' }}>
						{documentTitle}
					</div>
					<div style={{ width: '20vw' }}>
						{!notShareablePDFs.includes(documentFile) && <Button
							style={{
								float: 'right',
								border: '1px solid #470A68',
								color: '#470A68',
								borderRadius: '50%',
								minWidth: '4vh',
								minHeight: '4vh',
								maxWidth: '4vh',
								maxHeight: '4vh',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								background: 'white'
							}}
							//onClick={() => share(window.location.href, documentTitle)}
							onClick={() => {
								ReactGA.event({
									category: 'Content Share',
									action: `User shared ${documentTitle}`
								});

								window.open(
									`mailto:?subject=${documentTitle}&body=${filesUrl}${documentFile
										.replace(/ /g, '')
										.replace('-min.pdf', '.pdf')}`
								);
							}}
						>
							<i className="las la-upload" />
						</Button>}
					</div>
				</div>
				<div
					style={{
						height: '100%',
						width: '100%',
						overflowX: 'hidden',
						paddingTop: '7vh',
						paddingBottom: '5vh'
					}}
				>
					<Document
						file={pdfFile}
						onLoadSuccess={this.onDocumentLoadSuccess}
						onLoadError={(error) => alert('Error while loading document! ' + error.message)}
					>
						{pages.map((page) => page)}
					</Document>
				</div>
			</div>
		);
	}
}

export default withRouter(PdfViewer);
