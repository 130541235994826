import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class MenuAccordion extends Component {
	state = {
		expanded: false
	};

	expand = () => {
		this.setState({ expanded: !this.state.expanded });
	};

	render() {
		const { title, children, pageLink } = this.props;
		const { expanded } = this.state;
		return (
			<Container>
				<Row
					style={{ height: '7vh', display: 'flex', alignItems: 'center', borderBottom: '1px solid #BBBBBB' }}
				>
					<Col xs={12} style={{ paddingLeft: 0 }}>
						<Link
							to={pageLink}
							className="navTabs"
							activeClassName="active"
							style={{
								color: '#63666A',
								fontSize: '1.85vh',
								fontFamily: 'BrandonGrotesqueBold'
							}}
						>
							{title}{' '}
						</Link>
						{children && (
							<span style={{ float: 'right' }}>
								{expanded ? (
									<i className="las la-angle-down" onClick={this.expand} />
								) : (
									<i className="las la-angle-up" onClick={this.expand} />
								)}
							</span>
						)}
					</Col>
				</Row>
				{expanded && (
					<Row>
						<Col xs={12} style={{ paddingLeft: '20px', paddingRight: 0 }}>
							{children}
						</Col>
					</Row>
				)}
			</Container>
		);
	}
}

export default MenuAccordion;
