import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import NavMenu from './NavMenu';
import { Link } from 'react-router-dom';
import logo from '../assets/images/ABTLogo.png';
import { withRouter } from 'react-router-dom';

class NavBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			navMenuOpen: false
		};
	}

	componentDidMount() {
		this.props.history.listen(() => {
			this.setState({ navMenuOpen: false });
		});
	}

	toggleMenu = () => {
		this.setState({ navMenuOpen: !this.state.navMenuOpen });
	};

	render() {
		const { navMenuOpen } = this.state;

		return (
			<div>
				<div className="navbar">
					<Link to="/">
						<img src={logo} style={{ height: 25 }} />
					</Link>
					{!navMenuOpen ? (
						<i
							className="las la-bars pointer"
							style={{ float: 'right', fontSize: 20 }}
							onClick={this.toggleMenu}
						/>
					) : (
						<i
							className="las la-times pointer"
							style={{ float: 'right', fontSize: 20 }}
							onClick={this.toggleMenu}
						/>
					)}
				</div>
				{/* {navMenuOpen && <NavMenu toggleMenu={this.toggleMenu} logo={logo} />} */}
				<NavMenu toggleMenu={this.toggleMenu} isOpen={navMenuOpen} logo={logo} />
			</div>
		);
	}
}

export default withRouter(NavBar);
