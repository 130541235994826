import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

class IndicationSafetyWarnings extends Component {
	render() {
		return (
			<div className="page">
				<Container fluid="xl" className="withBreadcrumb">
					<Row style={{ height: '10vh' }}>
						<Col xs={12}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div
									style={{
										border: '1px solid #63666A',
										minWidth: '8vh',
										width: '8vh',
										minHeight: '8vh',
										height: '8vh',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										marginRight: 10
									}}
								>
									<i
										class="las la-exclamation-circle"
										style={{ fontSize: '5vh', color: '#63666A' }}
									/>
								</div>
								<h5 style={{ color: 'black', margin: 0, fontFamily: 'BrandonGrotesqueBold' }}>
									INDICATIONS, SAFETY AND WARNINGS
								</h5>
							</div>
						</Col>
					</Row>
					<br />
					<p >
						<strong>Rx ONLY</strong><br /><br />
						<strong>Brief Summary:</strong> Prior to using these devices, please review the Instructions for Use for a complete listing of indications, contraindications, warnings, precautions, potential adverse events and directions for use.
						<br /><br />
						<strong>Indications for Use:</strong> The Assert&#x2011;IQ™ ICM is indicated for the monitoring and diagnostic evaluation of patients who experience unexplained symptoms that may be cardiac-related such as: dizziness, palpitations, chest pain, syncope, and shortness of breath, as well as patients who are at risk for cardiac arrhythmias such as bradycardia, tachycardia, and sinus pauses. The Assert&#x2011;IQ ICM is also indicated for patients who have been previously diagnosed with atrial fibrillation (AF) or who are susceptible to developing AF. The Assert&#x2011;IQ ICM is intended to be inserted subcutaneously in the left pectoral region, also described as the left anterior chest wall. The Assert&#x2011;IQ ICM has not been specifically tested for pediatric use.
						<br /><br />
						<strong>Intended Use:</strong> The Assert&#x2011;IQ ICM is intended to help physicians and clinicians monitor, diagnose and document the heart rhythm in patients who are susceptible to cardiac arrhythmias and unexplained symptoms by detecting arrhythmias and transmitting data for review.
						<br /><br />
						<strong>Contraindications: </strong>There are no known contraindications for the insertion of the Assert&#x2011;IQ ICM. However, the patient’s particular medical condition may dictate whether or not a subcutaneous, chronically inserted device can be tolerated.
						<br /><br />
						<strong>Potential Adverse Events: </strong>Possible adverse events (in alphabetical order) associated with the device, include the following: allergic reaction, bleeding, chronic nerve damage, erosion, excessive fibrotic tissue growth, extrusion, formation of hematomas or cysts, infection, keloid formation and migration.
						<br /><br />
						Refer to the User’s Manual for detailed indications for use, contraindications, warnings, precautions and potential adverse events.
						<br /><br />
						An Abbott mobile transmitter is available for patients without their own compatible mobile device.
						<br /><br />
						™ Indicates a trademark of the Abbott group of companies.
						<br /><br />
						‡ Indicates a third-party trademark, which is property of its respective owner.
						<br /><br />
						Bluetooth and Bluetooth logo are registered trademarks of Bluetooth SIG, Inc.
						<br /><br />
						© 2024 Abbott. All Rights Reserved.<br />
						MAT-2304381 v2.0 | Item approved for U.S. use only.<br />
						Internal use only.<br />
					</p>

				</Container>
			</div>
		);
	}
}

export default withRouter(IndicationSafetyWarnings);
