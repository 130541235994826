import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Container, Row, Col, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import ProductImage from '../../assets/App_Imagery/01-Dashboard/Assert-IQ Product Image.png';
import AssertIQPatientBrochure from '../../assets/images/thumbnails/74330-Assert-IQ-Launch-Patient-Brochure.png';
import AssertIQPatientHandoutCard from '../../assets/images/thumbnails/76115-Assert-IQ-Patient-Business-Card.png';
import AssertIQQuickStartGuide from '../../assets/images/thumbnails/74958-Assert-IQ-Quick-Start-Guide.png';
import ICMSymptomChecklist from '../../assets/images/thumbnails/76283-ICM-Symptom-Checklist.png';
import WhattoExpectWhenReceivinganAbbottAssertIQICM from '../../assets/images/thumbnails/What-to-Expect-When-Receiving-an-Abbott-Assert-IQ-ICM_.png';
import myMerlinMobileAppforAssertIQICMHowtoConnect from '../../assets/images/thumbnails/myMerlin-Mobile-App-for-Assert-IQ-ICM-How-to-Connect.png';
import myMerlinMobileAppforAssertIQICMHowitWorks from '../../assets/images/thumbnails/myMerlin-Mobile-App-for-Assert-IQ-ICM-How-it-Works.png';
import myMerlinMobileAppforAssertIQICMLearnHowtoRecordSymptoms from '../../assets/images/thumbnails/myMerlin-Mobile-App-for-Assert-IQ-ICM-Learn-How-to-Record-Symptoms.png';


import myMerlinMobileAppforAssertIQICMLearnHowtoStayConnected from '../../assets/images/thumbnails/myMerlin-Mobile-App-for-Assert-IQ-ICM-Learn-How-to-Stay-Connected.png';

import spanishBrochureThumbnail from '../../assets/images/thumbnails/Assert-IQ Patient Brochure - SPANISH MAT-2308155v1.0.png'
class PatientResources extends Component {
	state = {
		searchText: '',
		sortingAsc: true,
		resources: [
			{
				title: ['Patient Brochure'],
				thumbnail: AssertIQPatientBrochure,
				fileName: '74330-Assert-IQ-Launch-Patient-Brochure1.pdf',
				type: 'pdf'
			},
			{
				title: ['Spanish Patient Brochure'],
				thumbnail: spanishBrochureThumbnail,
				fileName: 'Assert-IQ-Patient-Brochure-SPANISH-MAT-2308155v1.0.pdf',
				type: 'pdf'
			},
			{
				title: ['Patient Handout Card'],
				thumbnail: AssertIQPatientHandoutCard,
				fileName: '76115-Assert-IQ-Patient-Business-Card1.pdf',
				type: 'pdf'
			},
			{
				title: ['What to Expect When Receiving an Abbott Assert-IQ ICM'],
				thumbnail: WhattoExpectWhenReceivinganAbbottAssertIQICM,
				url: 'https://www.youtube.com/watch?v=rbFL0LaX6QM&list=PLLJGkrRsQxc3wYYPknx_T8AOVFl7zpqys&index=11',
				type: 'link'
			},
			{
				title: ['Connectivity Quick Start Guide'],
				thumbnail: AssertIQQuickStartGuide,
				fileName: '74958-Assert-IQ-Quick-Start-Guide1.pdf',
				type: 'pdf'
			},
			{
				title: ['Cardiac Monitoring Symptom Checklist'],
				thumbnail: ICMSymptomChecklist,
				fileName: '76283-ICM-Symptom-Checklist1.pdf',
				type: 'pdf'
			},
			{
				title: ['myMerlin Mobile App for Assert-IQ ICM: How to Connect'],
				thumbnail: myMerlinMobileAppforAssertIQICMHowtoConnect,
				url: 'https://www.youtube.com/watch?v=Wirrmg-i1UA&list=PLLJGkrRsQxc3wYYPknx_T8AOVFl7zpqys&index=15',
				type: 'link'
			},
			{
				title: ['myMerlin Mobile App for Assert-IQ ICM: How it Works'],
				thumbnail: myMerlinMobileAppforAssertIQICMHowitWorks,
				url: 'https://www.youtube.com/watch?v=WCS-9I-JGng&list=PLLJGkrRsQxc3wYYPknx_T8AOVFl7zpqys&index=12 ',
				type: 'link'
			},

			{
				title: ['myMerlin Mobile App for Assert-IQ ICM: Learn How to Record Symptoms'],
				thumbnail: myMerlinMobileAppforAssertIQICMLearnHowtoRecordSymptoms,
				url: 'https://www.youtube.com/watch?v=UaXXqU7-jUI&list=PLLJGkrRsQxc3wYYPknx_T8AOVFl7zpqys&index=13',
				type: 'link'
			},


			{
				title: ['myMerlin Mobile App for Assert-IQ ICM: Learn How to Stay Connected'],
				thumbnail: myMerlinMobileAppforAssertIQICMLearnHowtoStayConnected,
				url: 'https://www.youtube.com/watch?v=5yV0BXFK8zA&list=PLLJGkrRsQxc3wYYPknx_T8AOVFl7zpqys&index=14',
				type: 'link'
			},







		],
		displayAsList: true
	};

	searchResources = (e) => {
		const searchText = e.target.value;
		this.setState({ searchText: searchText });
	};

	sortResources = () => {
		const sortingAsc = !this.state.sortingAsc;
		const resources = this.state.resources.sort(
			(a, b) =>
				sortingAsc
					? a.title < b.title ? -1 : a.title > b.title ? 1 : 0
					: b.title < a.title ? -1 : b.title > a.title ? 1 : 0
		);
		this.setState({ sortingAsc: sortingAsc, resources: resources });
	};

	render() {
		const { resources, searchText, sortingAsc, displayAsList } = this.state;
		const filteredResources = resources.filter((rsrc) =>
			rsrc.title.toString().toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
		);

		return (
			<div className="page">
				<div className="sectionBreadcrumb">
					<Link className="pointer" to="/resource-libraries">
						<i className="las la-angle-left" /> RESOURCE LIBRARIES
					</Link>
				</div>
				<Container fluid="xl" className="withBreadcrumb">
					<Row className=" sectionBanner greenGradient" style={{ position: 'relative' }} >

						<div style={{ position: 'absolute', right: 0, maxHeight: '100%', display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
							<img src={ProductImage} style={{ width: '100%', height: '100%' }} />
						</div>
						<Col>
							<h4 className='white' style={{ margin: '50px 0' }} >PATIENT<br />RESOURCES</h4>
						</Col>
					</Row>

					<Row className="search-filter-row">
						<Col xs={12}>
							<InputGroup>
								<InputGroupAddon addonType="prepend">
									<InputGroupText className="inputGroupText">
										<i className="las la-search appPrimaryColor" />
									</InputGroupText>
								</InputGroupAddon>
								<Input
									placeholder="Search Patient Resources..."
									value={searchText}
									onChange={this.searchResources}
									className="inputSearch"
								/>
							</InputGroup>
							<div className="filter-section">
								<div className="alpha-sort">
									Sort By: <span onClick={this.sortResources}>{sortingAsc ? 'A - Z' : 'Z - A'}</span>
								</div>
								<div className="filter-view-section">
									<div
										className={displayAsList ? 'list-view-active' : 'list-view-inactive'}
										onClick={() => this.setState({ displayAsList: true })}
									>
										<i className="las la-th-list" />
									</div>

									<div
										className={!displayAsList ? 'list-view-active' : 'list-view-inactive'}
										onClick={() => this.setState({ displayAsList: false })}
									>
										<i className="las la-th-large" />
									</div>
								</div>
							</div>
						</Col>
					</Row>
					<br />
					<Row>
						<Col xs={12} />
					</Row>
					<Row>
						{displayAsList ? (
							filteredResources.map(
								(resource) =>
									resource.type === 'pdf' ? (
										<PDFListItem resource={resource} />
									) : (
										resource.type === 'link' && <LinkListItem resource={resource} />
									)
							)
						) : (
							filteredResources.map(
								(resource) =>
									resource.type === 'pdf' ? (
										<PDFTileItem resource={resource} />
									) : (
										resource.type === 'link' && <LinkTileItem resource={resource} />
									)
							)
						)}
						{filteredResources.length <= 0 && (
							<Col xs={12}>
								<p style={{ textAlign: 'center' }}>No results for "{searchText}"</p>
							</Col>
						)}
					</Row>

					<Row style={{ paddingTop: 40, paddingBottom: 40, marginTop: 'auto' }}>
						<Col xs={12}>
							<div
								style={{
									width: '100%',
									padding: '0 20px',
									paddingBottom: 10
								}}
							/>
							<div className="footNote">
								<p >
									© 2024 Abbott. All Rights Reserved.<br />
									MAT-2304391 v4.0 | Item approved for US use only.
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

class PDFListItem extends Component {
	render() {
		const { resource } = this.props;
		return (
			<Col xs={12}>
				<Link
					to={`/resource-libraries/pdf-viewer?title=${resource.title
						.map((t) => (t instanceof Object ? 'TM' : t))
						.join('')}&file=${resource.fileName}`}
				>
					<div className="list-item">
						<img src={resource.thumbnail} />
						<div className="list-item-title">{resource.title}</div>

						<i className="las la-angle-right" />
					</div>
				</Link>
			</Col>
		);
	}
}

class LinkListItem extends Component {
	render() {
		const { resource } = this.props;
		return (
			<Col xs={12}>
				<a href={`${resource.url}`} target="_blank" rel="noopener noreferrer">
					<div className="list-item">
						<img src={resource.thumbnail} />
						<div className="list-item-title">{resource.title}</div>

						<i className="las la-angle-right" />
					</div>
				</a>
			</Col>
		);
	}
}

class PDFTileItem extends Component {
	render() {
		const { resource } = this.props;
		return (
			<Col xs={6} lg={3}>
				<Link to={`/resource-libraries/pdf-viewer?title=${resource.title}&file=${resource.fileName}`}>
					<img src={resource.thumbnail} className="tileImage" />
					<div className="tile-item-title">{resource.title}</div>
				</Link>
			</Col>
		);
	}
}

class LinkTileItem extends Component {
	render() {
		const { resource } = this.props;
		return (
			<Col xs={6} lg={3}>
				<a href={`${resource.url}`}>
					<img src={resource.thumbnail} className="tileImage" />
					<div className="tile-item-title">{resource.title}</div>
				</a>
			</Col>
		);
	}
}

export default withRouter(PatientResources);
