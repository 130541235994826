import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

class GradientIconButton extends Component {
	render() {
		const { text, backgroundClass, linkPath, iconClass, onClick, iconImg, className } = this.props;
		return linkPath ? (
			<Link to={linkPath}>
				<Button className={`action-button ${className}`} style={{ background: 'white', textAlign: 'left' }}>
					<div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
						<div
							className={backgroundClass}
							style={{
								display: 'flex',
								alignItems: 'center',
								height: 60,
								width: 60,
								marginRight: 10
							}}
						>
							{iconImg ? (
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										textAlign: 'center',
										width: '100%'
									}}
								>
									<img
										src={iconImg}
										style={{
											maxHeight: 30,
											margin: 'auto',
										}}
										className="appPrimaryColor"
									/>
								</div>
							) : (
								<i className={iconClass} style={{ fontSize: 36, margin: 'auto' }} />
							)}
						</div>
						<span
							className='brandonBold black'
						>
							{text}
						</span>
						<i
							className="las la-angle-right"
							style={{ marginLeft: 'auto', marginRight: 10, color: '#63666A' }}
						/>
					</div>
				</Button>
			</Link>
		) : (
			<Button className={`action-button ${className}`} style={{ background: 'white', ...this.props.style }} onClick={onClick}>
				<div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
					<div
						className={backgroundClass}
						style={{
							display: 'flex',
							alignItems: 'center',
							height: '100%',
							marginRight: 15
						}}
					>
						{iconImg ? (
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									textAlign: 'center',
									width: '100%'
								}}
							>
								<img
									src={iconImg}
									style={{
										margin: 'auto',
										maxWidth: '50%'
									}}
								/>
							</div>
						) : (
							<i className={iconClass} style={{ fontSize: 36, margin: 'auto' }} />
						)}
					</div>
					<span
						className='brandonBold black'
					>
						{text}
					</span>
					<i
						className="las la-angle-right"
						style={{ marginLeft: 'auto', marginRight: 10, color: '#63666A' }}
					/>
				</div>
			</Button>
		);
	}
}

export default GradientIconButton;
