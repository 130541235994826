import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import MenuAccordion from './Buttons/MenuAccordion';

class NavMenu extends Component {
	render() {
		return (
			<div className={this.props.isOpen ? 'navMenuWrapper nav-active' : 'navMenuWrapper nav-inactive'}>
				<Container fluid="xl">
					<Row>
						<Col xs={12} style={{ paddingRight: 20, paddingLeft: 20 }}>
							<NavLink
								to="/resource-libraries/HCP-resources"
								className="menuItem purple"
								activeClassName="menuActive"
							>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										height: '60px',
										width: '100%',
										borderBottom: '1px solid #BBBBBB'
									}}
								>
									<p className="dropDownItem">HCP RESOURCES</p>

									<div className="activeMenuBar" />
								</div>
							</NavLink>
							<NavLink
								to="/resource-libraries/patient-resources"
								className="menuItem green"
								activeClassName="menuActive"
							>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										height: '60px',
										width: '100%',
										borderBottom: '1px solid #BBBBBB'
									}}
								>
									<p className="dropDownItem">PATIENT RESOURCES</p>

									<div className="activeMenuBar" />
								</div>
							</NavLink>
							<NavLink
								to="/resource-libraries/sales-information"
								className="menuItem blue"
								activeClassName="menuActive"
							>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										height: '60px',
										width: '100%',
										borderBottom: '1px solid #BBBBBB'
									}}
								>
									<p className="dropDownItem">SALES ONLY INFORMATION</p>

									<div className="activeMenuBar" />
								</div>
							</NavLink>

							<NavLink
								to="/resource-libraries/remote-programming"
								className="menuItem darkBlue"
								activeClassName="menuActive"
							>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										height: '60px',
										width: '100%',
										borderBottom: '1px solid #BBBBBB'
									}}
								>
									<p className="dropDownItem">REMOTE PROGRAMMING RESOURCES</p>

									<div className="activeMenuBar" />
								</div>
							</NavLink>

							<NavLink
								to="/indications-safety-warnings"
								className="menuItem"
								activeClassName="menuActive"
							>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										height: '60px',
										width: '100%',
										borderBottom: '1px solid #BBBBBB'
									}}
								>
									<p className="dropDownItem">INDICATIONS, SAFETY, AND WARNINGS</p>

									<div className="activeMenuBar" />
								</div>
							</NavLink>
							{/* <NavLink to="/references" className="menuItem" activeClassName="menuActive">
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										height: '60px',
										borderBottom: '1px solid #BBBBBB'
									}}
								>
									<p className="dropDownItem">REFERENCES</p>

									<div className="activeMenuBar" />
								</div>
							</NavLink> */}
						</Col>
					</Row>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							height: '60px',

							marginLeft: 5
						}}
					>
						<p style={{ fontSize: '10px', fontFamily: 'BrandonGrotesqueRegular', color: 'gray' }}>V 2.0.0</p>
					</div>
				</Container>
			</div>
		);
	}
}

export default NavMenu;
