import React, { Component } from 'react';
import { Modal, ModalBody, Container, Row, Col } from 'reactstrap';
import { isSafari, browserVersion, isMobile } from 'react-device-detect';

import GradientIconButton from '../Components/Buttons/GradientIconButton';
import ResourcesIconWhite from '../assets/App_Icons/01-Dashboard/Resource Icon White.png';
import PWAPrompt from 'react-ios-pwa-prompt';

import icon1 from '../assets/App_Icons/01-Dashboard/APP.4021516039.AssertIQ.BatteryIcon.r1.png';
import icon2 from '../assets/App_Icons/01-Dashboard/Icon_Data Monitoring_Large_White.png';
import icon3 from '../assets/App_Icons/01-Dashboard/APP.4021516039.AssertIQ.AlgorithmIcon.png';
import icon4 from '../assets/App_Icons/01-Dashboard/APP.4021516039.AssertIQ.EKGIcon.png';
import icon5 from '../assets/App_Icons/01-Dashboard/APP.4021516039.AssertIQ.RemoteIcon.png';

import ProductImage from '../assets/App_Imagery/01-Dashboard/Assert-IQ Product Image.png';

class Dashboard extends Component {
	state = {
		displayInstallPopup: false,
		deviceOS: ''
	};

	installPrompt = null;
	componentDidMount() {
		window.addEventListener('beforeinstallprompt', (e) => {
			// For older browsers
			e.preventDefault();
			this.installPrompt = e;
			if (!this.state.displayInstallPopup) this.setState({ deviceOS: 'android' });
		});
		if (!window.matchMedia('(display-mode: standalone)').matches) {
			if (!(isSafari && browserVersion < 13) && isMobile) {
				//this.setState({ displayInstallPopup: true });
				this.install();
			}
		}
	}
	install = async () => {
		if (isSafari) {
			this.setState({ deviceOS: 'apple', displayInstallPopup: true });
		} else if (this.installPrompt) {
			this.installPrompt.prompt();
			let outcome = await this.installPrompt.userChoice;
			if (outcome.outcome === 'accepted') {
				this.setState({ deviceOS: '', displayInstallPopup: true });
			}
		}
	};

	render() {
		const { deviceOS } = this.state;
		return (
			<div className="page">
				<Container fluid="xl">
					<Row className="banner headerImage" style={{ position: 'relative' }}>
						<div style={{ position: 'absolute', top: 20, right: 20, maxHeight: '100%' }}>
							<p style={{ color: '#64CCC9', textAlign: 'right', fontSize: 12 }}><span className='brandonBold'>ASSERT-IQ™</span><br />INSERTABLE CARDIAC MONITOR (ICM)</p>
						</div>
						<div style={{ position: 'absolute', right: 0, maxHeight: '100%' }}>
							<img src={ProductImage} style={{ width: '100%', maxHeight: 300 }} />
						</div>
						<Col xs={12} style={{ padding: 20, marginTop: 53 }}>
							<div style={{ color: 'white' }}>
								<div className="dashboard-header-slogan">
									<span>
										DESIGNED TO<br />
										OUTPERFORM,<br />
									</span>
									BUILT TO OUTLAST
								</div>
								<button className='bannerButton' onClick={() => window.open('https://www.cardiovascular.abbott/us/en/hcp/products/cardiac-rhythm-management/insertable-cardiac-monitors/assert-iq-icm.html', '_blank')}>
									LEARN MORE
								</button>
							</div>
						</Col>
					</Row>

					<Row>
						<Col style={{ margin: '40px 0' }}>
							<p>
								Introducing Assert-IQ™ Insertable Cardiac Monitor (ICM), the intelligent cardiac monitor built with advanced algorithms and patient insights to deliver clinically actionable data, on the longest lasting Bluetooth<sup>®</sup> ICM.<sup>†</sup>
							</p>
						</Col>
					</Row>

					<Row className="boxRow gradient-section-background">
						<Col xs={12} style={{ padding: 0 }}>
							<div className="containerBox">
								<div className="contentContainer">
									<div className="boxIcon gradient-icon-background">
										<img src={icon1} />
									</div>
									<div className="boxTitle">LONGEST LASTING ICM</div>
									<div className="boxDescription">
										<p>
											Assert&#x2011;IQ is the longest lasting Bluetooth<sup>®</sup> ICM that offers full functionality with no compromises. By offering two battery life options, Assert&#x2011;IQ supports the expanding patient management needs of a clinician’s practice.
										</p>
									</div>
									<div className="boxCTA" onClick={() => window.open('https://www.cardiovascular.abbott/us/en/hcp/products/cardiac-rhythm-management/insertable-cardiac-monitors/assert-iq-icm/why.html#bluetooth', '_blank')}>
										<div className="CTAText">
											LEARN MORE
										</div>
									</div>
								</div>
							</div>
						</Col>
						<Col xs={12} style={{ padding: 0 }} className="mt-40">
							<div className="containerBox">
								<div className="contentContainer">
									<div className="boxIcon gradient-icon-background">
										<img src={icon2} />
									</div>
									<div className="boxTitle">ADVANCED ALGORITHMS</div>
									<div className="boxDescription">
										<p>
											Advanced algorithms ensure only clinically actionable data is sent to customers. Our highly effective discriminators reduce false detections by 98.7% for AF and Pause, while maintaining 97.7% of true EGMs.<sup>1-3</sup>
										</p>
									</div>
									<div className="boxCTA" onClick={() => window.open('https://www.cardiovascular.abbott/us/en/hcp/products/cardiac-rhythm-management/insertable-cardiac-monitors/assert-iq-icm/why.html#algorithms', '_blank')}>
										<div className="CTAText">
											LEARN MORE
										</div>
									</div>
								</div>
							</div>
						</Col>
						<Col xs={12} style={{ padding: 0 }} className="mt-40">
							<div className="containerBox">
								<div className="contentContainer">
									<div className="boxIcon gradient-icon-background">
										<img src={icon3} />
									</div>
									<div className="boxTitle">IQ INSIGHTS</div>
									<div className="boxDescription">
										<p>
											With IQ Insights, clinicians can make data-driven decisions faster and more confidently. Assert&#x2011;IQ ICM captures these exciting new diagnostics: PVCs, body position and posture changes, elevated heart rate with and without activity, and more!<br /><br />The novel PVC detection algorithm offers the ability to capture consecutive events including couplets and triplets.<sup>4</sup>
										</p>
									</div>
									<div className="boxCTA" onClick={() => window.open('https://www.cardiovascular.abbott/us/en/hcp/products/cardiac-rhythm-management/insertable-cardiac-monitors/assert-iq-icm/why.html#insights', '_blank')}>
										<div className="CTAText">
											LEARN MORE
										</div>
									</div>
								</div>
							</div>
						</Col>
						<Col xs={12} style={{ padding: 0 }} className="mt-40">
							<div className="containerBox">
								<div className="contentContainer">
									<div className="boxIcon gradient-icon-background">
										<img src={icon4} />
									</div>
									<div className="boxTitle">CLEAR, CRISP EGMS</div>
									<div className="boxDescription">
										<p>
											Enhanced EGMs provide improved visualization of P&#x2011;waves, which could lead to a faster care decision for patients.<sup>5</sup>
										</p>
									</div>
									<div className="boxCTA" onClick={() => window.open('https://www.cardiovascular.abbott/us/en/hcp/products/cardiac-rhythm-management/insertable-cardiac-monitors/assert-iq-icm/why.html?utm_source=applet&utm_medium=organic&utm_product=assertiq&utm_region=us&utm_division=crm&utm_franchise=icm&utm_date=20230429&utm_audience=hcp&utm_content=assertapplet&utm_campaign=applet#arrhythmias', '_blank')}>
										<div className="CTAText">
											LEARN MORE
										</div>
									</div>
								</div>
							</div>
						</Col>
						<Col xs={12} style={{ padding: 0 }} className="mt-40">
							<div className="containerBox">
								<div className="contentContainer">
									<div className="boxIcon gradient-icon-background">
										<img src={icon5} />
									</div>
									<div className="boxTitle">REMOTE PROGRAMMABILITY</div>
									<div className="boxDescription">
										<p>
											Allows you to adjust the settings of the device, optimize performance, and limit unnecessary alerts or transmissions for connected devices - all without requiring the patient to visit the clinic.*
										</p>
									</div>
									<div className="boxCTA" onClick={() => window.open('https://www.cardiovascular.abbott/us/en/hcp/products/cardiac-rhythm-management/insertable-cardiac-monitors/assert-iq-icm/remote-programming.html', '_blank')}>
										<div className="CTAText">
											LEARN MORE
										</div>
									</div>
								</div>
							</div>
						</Col>
					</Row>

					<Row className='mt-40'>
						<Col >
							<p className='brandonBold mb-20'>
								RESOURCE LIBRARIES
							</p>
							<GradientIconButton
								className="mb-20"
								text="HCP RESOURCES"
								backgroundClass="purple-background"
								linkPath="/resource-libraries/HCP-resources"
								iconImg={ResourcesIconWhite}
							/>
							<GradientIconButton
								className="mb-20"
								text="PATIENT RESOURCES"
								backgroundClass="green-background"
								linkPath="/resource-libraries/patient-resources"
								iconImg={ResourcesIconWhite}
							/>
							<GradientIconButton
								className="mb-20"
								text="SALES ONLY INFORMATION"
								backgroundClass="blue-background"
								linkPath="/resource-libraries/sales-information"
								iconImg={ResourcesIconWhite}
							/>
							<GradientIconButton
								text="REMOTE PROGRAMMING RESOURCES"
								backgroundClass="dark-blue-background"
								linkPath="/resource-libraries/remote-programming"
								iconImg={ResourcesIconWhite}
							/>
						</Col>
					</Row>
					<br />
					<br />
					<Row className=' mb-40'>
						<Col >
							<p className='brandonBold mb-20'>
								IMPORTANT INFORMATION
							</p>
							<GradientIconButton
								text="IMPORTANT SAFETY INFORMATION"
								backgroundClass="dark-gray-bg"
								linkPath="/indications-safety-warnings"
								iconClass="las la-exclamation-circle"
							/>
						</Col>
					</Row>
					<Row className="banner gradient-section-background-h" style={{ position: 'relative', height: 224 }}>
						<Col xs={12} style={{ marginTop: 40, marginBottom: 40 }}>
							<div style={{ color: 'white', textAlign: 'center' }}>
								<div className="dashboard-header-slogan">

									<span>BE THE </span>
									FIRST
									<span> TO KNOW</span>
								</div>
								<p>Sign up today to receive the latest updates on all Assert&#x2011;IQ ICM product information and much more.</p>
								<button className='bannerButton' onClick={() => window.open("https://www.cardiovascular.abbott/us/en/campaigns/crm-signup.html?utm_source=applet&utm_medium=organic&utm_product=assertiq&utm_region=us&utm_division=crm&utm_franchise=icm&utm_date=20230429&utm_audience=hcp&utm_content=assertapplet&utm_campaign=applet", "_blank")}>
									SIGN UP
								</button>
							</div>
						</Col>
					</Row>
					<Row style={{ paddingTop: 40, paddingBottom: 50 }}>
						<Col xs={12}>
							<div
								style={{
									width: '100%',
									borderTop: '1px solid #BBBBBB',
									padding: '0 20px',
									paddingBottom: 10
								}}
							/>
							<div className="footNote">
								<p>
									© 2024 Abbott. All Rights Reserved.<br />
									MAT-2304380 v2.0 | Item approved for US use only.<br />
									† As of 12.31.22. Reveal LINQ<sup>‡</sup> User Manual, LINQ II<sup>‡</sup> User Manual, Lux Dx<sup>‡</sup> User Manual, Biomonitor III<sup>‡</sup> User Manual, Biomonitor IIIm<sup>‡</sup> User Manual.<br /><br />
									*Available on DM5300/ DM5500<br /><br /> 1. Gopinathannair R, Lakkireddy D, Manyam H, et al. Improving the Specificity of Atrial Fibrillation and Tachycardia Detection in an Insertable Cardiac Monitor. Presented at Heart Rhythm Society (HRS); San Francisco, USA; 2022.<br /><br />
									2. Afzal MR, Gopinathannair R, Manyam H, et al. Development and Evaluation of a New Algorithm Enhancement to Improve Specificity of Pause Detection in an Insertable Cardiac Monitor. Presented at Heart Rhythm Society (HRS); San Francisco, USA; 2022.<br /><br />
									3. Data on File. Abbott - Report SJM-CFM-0919-0163.<br /><br />
									4. Manyam H, Afzal MR, Gopinathannair R, et al. Evaluation of A Novel Premature Ventricular Contraction Detection Algorithm in an Insertable Cardiac Monitor. Presented at Heart Rhythm Society (HRS); San Francisco, USA; 2022.<br /><br />
									5. Shehata MM, Nair DG, Qu F, et al. Insertable Cardiac Monitor P-wave Visibility in a New Clinical Report. Presented at Asia Pacific Heart Rhythm Society (APHRS); Bangkok Thailand; 2022.<br /><br />
									6. Radtke, et al. Artificial Intelligence Enables Dramatic Reduction of False Atrial Fibrillation Alerts from Insertable Cardiac Monitors. Presented at Heart Rhythm Society (HRS); Boston, USA; Aug 2021.<br /><br />
									7. Data on File.Abbott - Report 90986479A; AF EGM Burden Reduction in Assert&#x2011;IQ ICM <br /><br />
									8. Shehata MM, Nair DG, Qu F, et al. Insertable Cardiac Monitor P-wave Visibility in a New Clinical Report. Presented at Asia Pacific Heart Rhythm Society (APHRS); Bangkok Thailand; 2022.
								</p>
							</div>
						</Col>
					</Row>
				</Container>

				{/* displayInstallPopup && (
					<PWAInstallPopup
						notInstalled={displayInstallPopup}
						toggle={() => this.setState({ displayInstallPopup: false })}
					/>
				) */}
				{deviceOS === 'apple' && (
					<div className="installInstructions">
						<PWAPrompt
							timesToShow={10}
							permanentlyHideOnDismiss={false}
							delay={10}
							debug={true}
							copyTitle="Install App"
							copyBody="This website offers app capabilities. Please add it to your home screen to improve performance and enable offline functionalities."
							copyClosePrompt={[<span style={{ fontFamily: 'BrandonGrotesqueBold' }}>DONE</span>]}
						/>
					</div>
				)}
				{deviceOS === 'android' && (
					<div className="installInstructions">
						<Modal
							isOpen={deviceOS === 'android'}
							toggle={() => this.setState({ deviceOS: '' })}
							style={{ maxWidth: 400, padding: 15, margin: 'auto' }}
						>
							<ModalBody>
								<p style={{ textAlign: 'center' }}>
									<span style={{ fontFamily: 'BrandonGrotesqueBold' }}>INSTALL APP</span>
									<br />
									This website offers app capabilities. Please add it to your home screen to improve
									performance and enable offline functionalities.
								</p>
								<GradientIconButton
									text="ADD TO HOMESCREEN"
									gradientClass="dark-gray-bg"
									onClick={this.install}
									iconClass="las la-download"
								/>
							</ModalBody>
						</Modal>
					</div>
				)}
			</div>
		);
	}
}

export default Dashboard;
