import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import GradientIconButton from '../Components/Buttons/GradientIconButton';
import ResourcesIconWhite from '../assets/App_Icons/01-Dashboard/ResourceLibraries-Icon White.png';
import HeaderImage from '../assets/App_Imagery/10-Resource Libraries/Assert-IQ BG Gradient.png';

class ResourceLibraries extends Component {
	render() {
		return (
			<div className="page">
				<Container fluid="xl">
					<Row className="headerImageImportantInformation">
						<Col>
							<h4 style={{ marginTop: 39 }} className="white">
								RESOURCE<br />
								<span style={{ fontFamily: 'BrandonGrotesqueBold' }}>LIBRARIES</span>
							</h4>
						</Col>
					</Row>
					<br />
					<br />
					<Row>
						<Col>
							<GradientIconButton
								className="mb-20"
								text={['HCP RESOURCES']}
								backgroundClass="purple-background"
								linkPath="/resource-libraries/HCP-resources"
								iconImg={ResourcesIconWhite}
							/>
							<GradientIconButton
								className="mb-20"
								text={['PATIENT RESOURCES']}
								backgroundClass="green-background"
								linkPath="/resource-libraries/patient-resources"
								iconImg={ResourcesIconWhite}
							/>

							<GradientIconButton
								className="mb-20"
								text={['SALES ONLY INFORMATION']}
								backgroundClass="blue-background"
								linkPath="/resource-libraries/sales-information"
								iconImg={ResourcesIconWhite}
							/>

							<GradientIconButton
								text={['REMOTE PROGRAMMING RESOURCES']}
								backgroundClass="dark-blue-background"
								linkPath="/resource-libraries/remote-programming"
								iconImg={ResourcesIconWhite}
							/>
						</Col>
					</Row>
					<br />
					<br />

					<Row style={{ paddingTop: 40, paddingBottom: 40, marginTop: 'auto' }}>
						<Col xs={12}>
							<div
								style={{
									width: '100%',
									borderTop: '1px solid #BBBBBB',
									padding: '0 20px',
									paddingBottom: 10
								}}
							/>
							<div className="footNote">
								<p >
									© 2024 Abbott. All Rights Reserved.<br />
									MAT-2304396 v2.0 | Item approved for US use only.
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default ResourceLibraries;
