import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Container, Row, Col, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import ProductImage from '../../assets/App_Imagery/01-Dashboard/Assert-IQ Product Image.png';
import LaunchChecklist from '../../assets/images/thumbnails/Assert-IQ Launch Checklist MAT-2303609 v2.0.png';
import SellSheet from '../../assets/images/thumbnails/76059-Assert-IQ-Sell-Sheet.png';
//import ICMWorkflowGuide from '../../assets/images/thumbnails/76063-ICM-workflow-guide.png'; old
import ICMWorkflowGuide from '../../assets/images/thumbnails/WKFL.5823849750.ICM.AssertIQ.WorkflowGuide.EMEA.GL.EXT.WR.png';

import MerlinSoftwareUpdateGuide from '../../assets/images/thumbnails/23-77084-Merlin-3650-PCS-Software-Update-Guide.png';
import IndicationsandIntendedUseWorkshop from '../../assets/images/thumbnails/13-Assert-IQ-Indications-and-Intended-Use-Workshop-MAT-2403504v1.png';
import LaunchBroadcast from '../../assets/images/thumbnails/23-77549-Broadcast-Video.png';
import SellingwithIntentionWorksheet from '../../assets/images/thumbnails/76760-Selling-with-Intention-Worksheet.png';
import FirstImplantSocialPrimers from '../../assets/images/thumbnails/First-Implant-Social-Primers.png';
import ModuleProductDifferentiationfortheAssertIQICM from '../../assets/images/thumbnails/Module-Product-Differentiation-for-the-Assert-IQ-ICM.png';
import ModuleWhyWhatWhereStrategicallyPositioningtheAssertIQICM from '../../assets/images/thumbnails/Module-Why-What-Where-Strategically-Positioning-the-Assert-IQ-ICM.png';
import ModuleConstructingValuePropositionsfortheAssertIQICM from '../../assets/images/thumbnails/Module-Constructing-Value-Propositions-for-the-Assert-IQ-ICM.png';
import ModuleManagingObjectionsoftheAssertIQICMSystemRealExamples from '../../assets/images/thumbnails/08-Module-Managing-Objections-MAT-2401774v1.png';
import ICMTechnicalInsightLOCDiscriminator from '../../assets/images/thumbnails/ICM-Technical-Insight–LOC-Discriminator.png';
import MRIFAQ from '../../assets/images/thumbnails/MRI-FAQ.png';

import EmailWherePrecisionMeetsSimplicityinCardiacMonitoring from '../../assets/images/thumbnails/Assert-IQ™ 3 ICM_ Where Precision Meets Simplicity in Cardiac Monitoring.png';
import InterrogatingAssertIQICMDevices from '../../assets/images/thumbnails/Interrogating Assert-IQ ICM Devices- A Best Practices Guide...MAT-2311288v1.0.png'

class SalesInformation extends Component {
	state = {
		searchText: '',
		sortingAsc: true,
		resources: [
			{
				title: ['Launch Ready Checklist'],
				thumbnail: LaunchChecklist,
				fileName: 'Assert-IQ-Launch-Checklist-MAT-2303609-v2.0.pdf',
				type: 'pdf'

			},
			{
				title: ['Internal Launch Broadcast for Assert-IQ ICM'],
				thumbnail: LaunchBroadcast,
				url: 'https://abbott-assets.s3.us-west-2.amazonaws.com/Assert/pdf/23-77549-Broadcast-Video.mp4',
				type: 'link'
			},
			{
				title: ['Customer Introductory Email Template'],
				url: "https://abbott-assets.s3.us-west-2.amazonaws.com/Assert/pdf/Introducing+Assert-IQ%E2%84%A2+Insertable+Cardiac+Monitor+the+LONGEST+LASTING+ICM+(1).msg",
				type: 'link'
			},
			/* {
				title: ['Physician Sell Sheet Flyer'],
				thumbnail: SellSheet,
				fileName: 'Physician-Sell-Sheet-Flyer.pdf',
				type: 'pdf'
			}, */
			{
				title: ['First Implant Social Primers'],
				thumbnail: FirstImplantSocialPrimers,
				fileName: 'First-Implant-Social-Primers.pdf',
				type: 'pdf'
			},
			{
				title: ['Module: Product Differentiation for the Assert-IQ ICM'],
				thumbnail: ModuleProductDifferentiationfortheAssertIQICM,
				fileName: '05-Module-Product-Differentiation-MAT-2402177v1.pdf',
				type: 'pdf'
			},
			{
				title: ['Module: Why, What, Where: Strategically Positioning the Assert-IQ ICM'],
				thumbnail: ModuleWhyWhatWhereStrategicallyPositioningtheAssertIQICM,
				fileName: '06-Module-Strategic-Positioning-MAT-2402173v1.pdf',
				type: 'pdf'
			},
			{
				title: ['Module: Constructing Value Propositions for the Assert-IQ ICM'],
				thumbnail: ModuleConstructingValuePropositionsfortheAssertIQICM,
				fileName: '07-Module-Value-Propositions-MAT-2402172v1.pdf',
				type: 'pdf'
			},
			{
				title: ['Module: Managing Objections of the Assert-IQ ICM System with Real Examples'],
				thumbnail: ModuleManagingObjectionsoftheAssertIQICMSystemRealExamples,
				fileName: '08-Module-Managing-Objections-MAT-2401774v1.pdf',
				type: 'pdf'
			},
			{
				title: ['Education Technical Reference Guides (7)'],
				thumbnail: '',
				url: 'https://www.myloopaccount.com/content/88902097-afb5-46ca-8f73-68d5c5251cff?size=20&page=0&data=%7B%22folderIdentity%22%3A%2288902097-afb5-46ca-8f73-68d5c5251cff%22%7D',
				type: 'link'
			},
			{
				title: ['ICM Connectivity Workflow Guide – Competitive Comparisons'],
				thumbnail: ICMWorkflowGuide,
				fileName: 'WKFL.5823849750.ICM.AssertIQ.WorkflowGuide.EMEA.GL.EXT.WR.pdf',
				type: 'pdf'
			},
			/* {
				title: ['ICM Technical Insight – LOC Discriminator'],
				thumbnail: ICMTechnicalInsightLOCDiscriminator,
				fileName: 'ICM-Technical-Insight–LOC-Discriminator.pdf',
				type: 'pdf'
			}, */

			{
				title: ['ICM Technical Insight – Best Practices Guide for the Merlin PCS 3650 Programmer'],
				thumbnail: InterrogatingAssertIQICMDevices,
				fileName: 'Interrogating-Assert-IQ-ICM-Devices-A-Best-Practices-Guide...MAT-2311288v1.0.pdf',
				type: 'pdf'
			},

			{
				title: ['ICM Selling with Intention Workshop'],
				thumbnail: SellingwithIntentionWorksheet,
				fileName: '76760-Selling-with-Intention-Worksheet1.pdf',
				type: 'pdf'
			},
			{
				title: ['ICM Indication and Intended Use Workshop'],
				thumbnail: IndicationsandIntendedUseWorkshop,
				fileName: '13-Assert-IQ-Indications-and-Intended-Use-Workshop-MAT-2403504v1.pdf',
				type: 'pdf'
			},
			{
				title: ['MRI FAQ'],
				thumbnail: MRIFAQ,
				fileName: 'MRI-FAQ.pdf',
				type: 'pdf'
			},
			/* {
				title: ['Merlin 3650 Software Update Guide'],
				thumbnail: MerlinSoftwareUpdateGuide,
				fileName: '23-77084-Merlin-3650-PCS-Software-Update-Guide1.pdf',
				type: 'pdf'
			}, */
			/* {
				title: ['Email: Where Precision Meets Simplicity in Cardiac Monitoring'],
				thumbnail: EmailWherePrecisionMeetsSimplicityinCardiacMonitoring,
				url: '/Assert-IQ™ 3 ICM_ Where Precision Meets Simplicity in Cardiac Monitoring.eml',
				type: 'link',
				isDownload: true
			}, */
		],
		displayAsList: true
	};

	searchResources = (e) => {
		const searchText = e.target.value;
		this.setState({ searchText: searchText });
	};

	sortResources = () => {
		const sortingAsc = !this.state.sortingAsc;
		const resources = this.state.resources.sort(
			(a, b) =>
				sortingAsc
					? a.title < b.title ? -1 : a.title > b.title ? 1 : 0
					: b.title < a.title ? -1 : b.title > a.title ? 1 : 0
		);
		this.setState({ sortingAsc: sortingAsc, resources: resources });
	};

	render() {
		const { resources, searchText, sortingAsc, displayAsList } = this.state;
		const filteredResources = resources.filter((rsrc) =>
			rsrc.title.toString().toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
		);
		return (
			<div className="page">
				<div className="sectionBreadcrumb">
					<Link className="pointer" to="/resource-libraries">
						<i className="las la-angle-left" /> RESOURCE LIBRARIES
					</Link>
				</div>
				<Container fluid="xl" className="withBreadcrumb">
					<Row className=" sectionBanner blueGradient" style={{ position: 'relative' }} >

						<div style={{ position: 'absolute', right: 0, maxHeight: '100%', display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
							<img src={ProductImage} style={{ width: '100%', height: '100%' }} />
						</div>
						<Col>
							<h4 className='white' style={{ margin: '50px 0' }} >SALES ONLY<br />INFORMATION</h4>
						</Col>
					</Row>

					<Row className="search-filter-row">
						<Col xs={12}>
							<InputGroup>
								<InputGroupAddon addonType="prepend">
									<InputGroupText className="inputGroupText">
										<i className="las la-search appPrimaryColor" />
									</InputGroupText>
								</InputGroupAddon>
								<Input
									placeholder="Search Sales Only Information..."
									value={searchText}
									onChange={this.searchResources}
									className="inputSearch"
								/>
							</InputGroup>
							<div className="filter-section">
								<div className="alpha-sort">
									Sort By: <span onClick={this.sortResources}>{sortingAsc ? 'A - Z' : 'Z - A'}</span>
								</div>
								<div className="filter-view-section">
									<div
										className={displayAsList ? 'list-view-active' : 'list-view-inactive'}
										onClick={() => this.setState({ displayAsList: true })}
									>
										<i className="las la-th-list" />
									</div>

									<div
										className={!displayAsList ? 'list-view-active' : 'list-view-inactive'}
										onClick={() => this.setState({ displayAsList: false })}
									>
										<i className="las la-th-large" />
									</div>
								</div>
							</div>
						</Col>
					</Row>
					<Row>
						{displayAsList ? (
							filteredResources.map(
								(resource) =>
									resource.type === 'pdf' ? (
										<PDFListItem resource={resource} />
									) : (
										resource.type === 'link' && <LinkListItem resource={resource} />
									)
							)
						) : (
							filteredResources.map(
								(resource) =>
									resource.type === 'pdf' ? (
										<PDFTileItem resource={resource} />
									) : (
										resource.type === 'link' && <LinkTileItem resource={resource} />
									)
							)
						)}
						{filteredResources.length <= 0 && (
							<Col xs={12}>
								{searchText != '' && <p style={{ textAlign: 'center' }}>No results for "{searchText}"</p>}
							</Col>
						)}
					</Row>

					<Row style={{ paddingTop: 40, paddingBottom: 40, marginTop: 'auto' }}>
						<Col xs={12}>
							<div
								style={{
									width: '100%',
									padding: '0 20px',
									paddingBottom: 10
								}}
							/>
							<div className="footNote">
								<p >
									© 2024 Abbott. All Rights Reserved.<br />
									MAT-2304385 v6.0 | Item approved for US use only.
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

class PDFListItem extends Component {
	render() {
		const { resource } = this.props;
		return (
			<Col xs={12}>
				<Link
					to={`/resource-libraries/pdf-viewer?title=${resource.title
						.map((t) => (t instanceof Object ? 'TM' : t))
						.join('')}&file=${resource.fileName}`}
				>
					<div className="list-item">
						<img src={resource.thumbnail} />
						<div className="list-item-title">{resource.title}</div>

						<i className="las la-angle-right" />
					</div>
				</Link>
			</Col>
		);
	}
}

class LinkListItem extends Component {
	render() {
		const { resource } = this.props;
		return (
			<Col xs={12}>
				<a href={`${resource.url}`} target="_blank" rel="noopener noreferrer">
					<div className="list-item">
						<img src={resource.thumbnail} />
						<div className="list-item-title">{resource.title}</div>

						<i className="las la-angle-right" />
					</div>
				</a>
			</Col>
		);
	}
}

class PDFTileItem extends Component {
	render() {
		const { resource } = this.props;
		return (
			<Col xs={6} lg={3}>
				<Link to={`/resource-libraries/pdf-viewer?title=${resource.title}&file=${resource.fileName}`}>
					<img src={resource.thumbnail} className="tileImage" />
					<div className="tile-item-title">{resource.title}</div>
				</Link>
			</Col>
		);
	}
}

class LinkTileItem extends Component {
	render() {
		const { resource } = this.props;
		return (
			<Col xs={6} lg={3}>
				<a href={`${resource.url}`} target={`${resource.isDownload ? '_self' : ''}`} download>
					<img src={resource.thumbnail} className="tileImage" />
					<div className="tile-item-title">{resource.title}</div>
				</a>
			</Col>
		);
	}
}

export default withRouter(SalesInformation);
