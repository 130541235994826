import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Container, Row, Col, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import ProductImage from '../../assets/App_Imagery/01-Dashboard/Assert-IQ Product Image.png';
import RemoteProgrammingAuthorizationForm from '../../assets/images/thumbnails/23-76853-Assert-IQ-Remote-Programming-Authorization-Form.png';
import RemoteProgrammingRequestEmailTemplate from '../../assets/images/thumbnails/23-77087-Assert-IQ-ICM-Remote-Programming-Request-Email-Template.png';
import merlinNetPCNRemoteProgrammingGuide from '../../assets/images/thumbnails/23-77317-Assert-IQ-Connectivity-Remote-Programming-Guide-One-Page.png';
import APhysiciansGuidetoRemoteProgrammingfortheAssertIQICM from '../../assets/images/thumbnails/A-Physician’s-Guide-to-Remote-Programming-for-the-Assert-IQ-ICM.png';

class RemoteProgramming extends Component {
    state = {
        searchText: '',
        sortingAsc: true,
        resources: [
            {
                title: ['A Physician’s Guide to Remote Programming for the Assert-IQ ICM'],
                thumbnail: APhysiciansGuidetoRemoteProgrammingfortheAssertIQICM,
                url: 'https://www.youtube.com/watch?v=Mj9VtOMzrBw&list=PLLJGkrRsQxc3wYYPknx_T8AOVFl7zpqys&index=16',
                type: 'link'
            },
            {
                title: ['Merlin.net™ PCN Remote Programming Guide'],
                thumbnail: merlinNetPCNRemoteProgrammingGuide,
                fileName: '02-Merlin-net-PCN-Remote-Programming-MAT-2304565v2.pdf',
                type: 'pdf'
            },
            {
                title: ['Clinician Remote Programming Authorization Form'],
                thumbnail: RemoteProgrammingAuthorizationForm,
                fileName: '23-76853-Assert-IQ-Remote-Programming-Authorization-Form1.pdf',
                type: 'pdf'
            },
            {
                title: ['Clinician Remote Programming Authorization Email Template'],
                thumbnail: RemoteProgrammingRequestEmailTemplate,
                fileName: '23-77087-Assert-IQ-ICM-Remote-Programming-Request-Email-Template1.pdf',
                type: 'pdf'
            },
        ],
        displayAsList: true
    };

    searchResources = (e) => {
        const searchText = e.target.value;
        this.setState({ searchText: searchText });
    };

    sortResources = () => {
        const sortingAsc = !this.state.sortingAsc;
        const resources = this.state.resources.sort(
            (a, b) =>
                sortingAsc
                    ? a.title < b.title ? -1 : a.title > b.title ? 1 : 0
                    : b.title < a.title ? -1 : b.title > a.title ? 1 : 0
        );
        this.setState({ sortingAsc: sortingAsc, resources: resources });
    };

    render() {
        const { resources, searchText, sortingAsc, displayAsList } = this.state;
        const filteredResources = resources.filter((rsrc) =>
            rsrc.title.toString().toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
        );

        return (
            <div className="page">
                <div className="sectionBreadcrumb">
                    <Link className="pointer" to="/resource-libraries">
                        <i className="las la-angle-left" /> RESOURCE LIBRARIES
                    </Link>
                </div>
                <Container fluid="xl" className="withBreadcrumb">
                    <Row className=" sectionBanner darkBlueGradient" style={{ position: 'relative' }} >

                        <div style={{ position: 'absolute', right: 0, maxHeight: '100%', display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                            <img src={ProductImage} style={{ width: '100%', height: '100%' }} />
                        </div>
                        <Col>
                            <h4 className='white' style={{ margin: '50px 0' }} >REMOTE PROGRAMMING<br />RESOURCES</h4>
                        </Col>
                    </Row>

                    <Row className="search-filter-row">
                        <Col xs={12}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText className="inputGroupText">
                                        <i className="las la-search appPrimaryColor" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    placeholder="Search Patient Resources..."
                                    value={searchText}
                                    onChange={this.searchResources}
                                    className="inputSearch"
                                />
                            </InputGroup>
                            <div className="filter-section">
                                <div className="alpha-sort">
                                    Sort By: <span onClick={this.sortResources}>{sortingAsc ? 'A - Z' : 'Z - A'}</span>
                                </div>
                                <div className="filter-view-section">
                                    <div
                                        className={displayAsList ? 'list-view-active' : 'list-view-inactive'}
                                        onClick={() => this.setState({ displayAsList: true })}
                                    >
                                        <i className="las la-th-list" />
                                    </div>

                                    <div
                                        className={!displayAsList ? 'list-view-active' : 'list-view-inactive'}
                                        onClick={() => this.setState({ displayAsList: false })}
                                    >
                                        <i className="las la-th-large" />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={12} />
                    </Row>
                    <Row>
                        {displayAsList ? (
                            filteredResources.map(
                                (resource) =>
                                    resource.type === 'pdf' ? (
                                        <PDFListItem resource={resource} />
                                    ) : (
                                        resource.type === 'link' && <LinkListItem resource={resource} />
                                    )
                            )
                        ) : (
                            filteredResources.map(
                                (resource) =>
                                    resource.type === 'pdf' ? (
                                        <PDFTileItem resource={resource} />
                                    ) : (
                                        resource.type === 'link' && <LinkTileItem resource={resource} />
                                    )
                            )
                        )}
                        {filteredResources.length <= 0 && (
                            <Col xs={12}>
                                <p style={{ textAlign: 'center' }}>No results for "{searchText}"</p>
                            </Col>
                        )}
                    </Row>

                    <Row style={{ paddingTop: 40, paddingBottom: 40, marginTop: 'auto' }}>
                        <Col xs={12}>
                            <div
                                style={{
                                    width: '100%',
                                    padding: '0 20px',
                                    paddingBottom: 10
                                }}
                            />
                            <div className="footNote">
                                <p >
                                    © 2024 Abbott. All Rights Reserved.<br />
                                    MAT-2309149 v2.0 | Item approved for US use only.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

class PDFListItem extends Component {
    render() {
        const { resource } = this.props;
        return (
            <Col xs={12}>
                <Link
                    to={`/resource-libraries/pdf-viewer?title=${resource.title
                        .map((t) => (t instanceof Object ? 'TM' : t))
                        .join('')}&file=${resource.fileName}`}
                >
                    <div className="list-item">
                        <img src={resource.thumbnail} />
                        <div className="list-item-title">{resource.title}</div>

                        <i className="las la-angle-right" />
                    </div>
                </Link>
            </Col>
        );
    }
}

class LinkListItem extends Component {
    render() {
        const { resource } = this.props;
        return (
            <Col xs={12}>
                <a href={`${resource.url}`} target="_blank" rel="noopener noreferrer">
                    <div className="list-item">
                        <img src={resource.thumbnail} />
                        <div className="list-item-title">{resource.title}</div>

                        <i className="las la-angle-right" />
                    </div>
                </a>
            </Col>
        );
    }
}

class PDFTileItem extends Component {
    render() {
        const { resource } = this.props;
        return (
            <Col xs={6} lg={3}>
                <Link to={`/resource-libraries/pdf-viewer?title=${resource.title}&file=${resource.fileName}`}>
                    <img src={resource.thumbnail} className="tileImage" />
                    <div className="tile-item-title">{resource.title}</div>
                </Link>
            </Col>
        );
    }
}

class LinkTileItem extends Component {
    render() {
        const { resource } = this.props;
        return (
            <Col xs={6} lg={3}>
                <a href={`${resource.url}`}>
                    <img src={resource.thumbnail} className="tileImage" />
                    <div className="tile-item-title">{resource.title}</div>
                </a>
            </Col>
        );
    }
}

export default withRouter(RemoteProgramming);
