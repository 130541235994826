import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

import DashboardIconWhite from '../assets/App_Icons/01-Dashboard/Dashboard-Icon White.png';
import ResourcesIconWhite from '../assets/App_Icons/01-Dashboard/ResourceLibraries-Icon White.png';

class TabBar extends Component {
	render() {
		return (
			<div className="tabBar">
				<Container>
					<Row className="jc-sb ">
						<Col xs={3} style={{ textAlign: 'center', paddingLeft: 5, paddingRight: 5 }}>
							<NavLink to="/" exact className="navTabs" activeClassName="selected">
								<div className="navTabsText">
									<div className="tabIconContainer">
										<img className="tabbarIcon selected" src={DashboardIconWhite} />
										<img className="tabbarIcon" src={DashboardIconWhite} />
									</div>
									HOME
								</div>
							</NavLink>
						</Col>

						<Col xs={3} style={{ textAlign: 'center', paddingLeft: 5, paddingRight: 5 }}>
							<NavLink to="/resource-libraries" className="navTabs" activeClassName="selected">
								<div className="navTabsText">
									<div className="tabIconContainer">
										<img className="tabbarIcon selected" src={ResourcesIconWhite} />
										<img className="tabbarIcon" src={ResourcesIconWhite} />
									</div>
									RESOURCE LIBRARIES
								</div>
							</NavLink>
						</Col>
						<Col xs={3} style={{ textAlign: 'center', paddingLeft: 5, paddingRight: 5 }}>
							<NavLink to="/indications-safety-warnings" className="navTabs" activeClassName="selected">
								<div className="navTabsText">
									<div className="tabIconContainer">
										<i
											className="las la-exclamation-circle"
											style={{ color: 'white', fontSize: 25 }}
										/>
									</div>
									IMPORTANT INFO
								</div>
							</NavLink>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default TabBar;
