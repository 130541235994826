import React, { Component } from 'react';
import { Router, Switch, Route, Link } from 'react-router-dom';
import NavBar from '../Components/NavBar';
import TabBar from '../Components/TabBar';
import Dashboard from '../Pages/Dashboard';
import ResourceLibraries from '../Pages/ResourceLibraries';

import HCPResources from '../Sections/ResourceLibraries/HCPResources';
import PatientResources from '../Sections/ResourceLibraries/PatientResources';
import SalesInformation from '../Sections/ResourceLibraries/SalesInformation';
import RemoteProgramming from '../Sections/ResourceLibraries/RemoteProgramming';

import PdfViewer from '../Components/PopUp/PdfViewer';
import IndicationSafetyWarnings from '../Pages/IndicationSafetyWarnings';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';

const history = createBrowserHistory();
history.listen((location) => {
	if (navigator.serviceWorker) {
		navigator.serviceWorker.getRegistrations().then((registrationsArray) => {
			registrationsArray[0] && registrationsArray[0].update();
		});
	}
	ReactGA.set({ page: location.pathname });
	ReactGA.pageview(location.pathname);
});

function parseJwt(token) {
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(
		atob(base64)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
	);

	return JSON.parse(jsonPayload);
}
function setCookie(cname, cvalue, exdays) {
	const d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	let expires = 'expires=' + d.toUTCString();
	document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}
function getCookie(cname) {
	let name = cname + '=';
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}
class router extends Component {
	constructor(props) {
		super(props);
		var appState = {
			isAuth: false
		};
		var urlString = window.location.search;
		var urlParams = new URLSearchParams(urlString);
		var jwt = urlParams.get('jwt');
		var parsedJwt = jwt && parseJwt(jwt);
		const storedEid = getCookie('eid');
		if (parsedJwt && parsedJwt.eid && parsedJwt.eid.toLowerCase().includes('abbott')) {
			setCookie('eid', parsedJwt.eid, 1);
			window.location.replace(window.location.origin);
		} else if (storedEid) {
			appState = { ...appState, isAuth: true };
		} else {
			window.open(`https://abbott-sso.s2client.com?ref=${window.location.host}`, '_self');
		}
		this.state = appState;
	}
	componentDidMount() {
		ReactGA.pageview(window.location.pathname);
	}

	render() {
		const { isAuth } = this.state;
		return (
			isAuth && (
				<Router history={history}>
					<NavBar />
					<TabBar />
					<Switch>
						<Route path="/" exact>
							<Dashboard />
						</Route>

						<Route path="/resource-libraries" exact>
							<ResourceLibraries />
						</Route>
						<Route path="/resource-libraries/HCP-resources" exact>
							<HCPResources />
						</Route>
						<Route path="/resource-libraries/patient-resources" exact>
							<PatientResources />
						</Route>
						<Route path="/resource-libraries/sales-information" exact>
							<SalesInformation />
						</Route>
						<Route path="/resource-libraries/remote-programming" exact>
							<RemoteProgramming />
						</Route>

						<Route path="/resource-libraries/pdf-viewer" exact>
							<PdfViewer />
						</Route>

						<Route path="/indications-safety-warnings" exact>
							<IndicationSafetyWarnings />
						</Route>

					</Switch>
				</Router>
			)
		);
	}
}

export default router;
