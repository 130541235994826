import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App/App';
import * as serviceWorker from './serviceWorker';
import swConfig from './swConfig';
import { pdfjs } from 'react-pdf';
import ReactGA from 'react-ga';
const pdfjsWorker = import('pdfjs-dist/build/pdf.worker.entry');
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
//pdfjs.GlobalWorkerOptions.workerSrc = `https://abbot-assets.s3-us-west-2.amazonaws.com/dev/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
//pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/scripts/pdf.worker.js`;

//TODO: update this to use the correct GA code
//ReactGA.initialize('UA-165926687-22');

//TODO: update this to use the correct Sentry code
/* Sentry.init({
	dsn: 'https://ed0105ae5dc048fb8e29ca04b917b5a9@o124651.ingest.sentry.io/6317513',
	integrations: [ new BrowserTracing() ],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0
}); */

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
serviceWorker.register(swConfig);
