import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Container, Row, Col, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';

import ProductImage from '../../assets/App_Imagery/01-Dashboard/Assert-IQ Product Image.png';
import assertIQHCPBrochure from '../../assets/images/thumbnails/74259-Assert-IQ-HCP-Brochure.png';
import assertIQClinicalCompendium from '../../assets/images/thumbnails/06-Assert-IQ-Clinical-Compendium-MAT-2303062v3.png';
import assertIQSymptomEpisodeSample from '../../assets/images/thumbnails/23-76811-Assert-IQ-Symptom-Episode-Sample.png';
import assertIQICMCatalogPage from '../../assets/images/thumbnails/74731-Assert-IQ-ICM-Catalog-Page.png';
import merlin3650SWv2701rev1ExternalMemo from '../../assets/images/thumbnails/23-77455-Merlin-3650-SW-v27.0.1rev1-External-Memo.png';
import mRIReadySystemsByAbbott from '../../assets/images/thumbnails/Abbott MRI Ready Systems_US MAT-2006170 v6.0.png';
import champDocument from '../../assets/images/thumbnails/Champ-Document.png';
import repToPhysicianPresentation from '../../assets/images/thumbnails/Rep-to-Physician-Presentation.png';
import PCNPatientReport from '../../assets/images/thumbnails/SampleMerlin.net-PCN-Patient-Report.png';
import IntroducingAssertIQICMTheLatestCardiacMonitoringInnovation from '../../assets/images/thumbnails/Introducing-Assert-IQ-ICM-The-Latest-Cardiac-Monitoring-Innovation.png';
import AssertIQICMAbbottslatestinnovationincardiacmonitoring from '../../assets/images/thumbnails/Assert-IQICM–Abbott’s-latest-innovation-in-cardiac-monitoring.png';
import AssertIQICMInsertionProcedureandProgrammingvideo from '../../assets/images/thumbnails/Assert-IQ-ICM-Insertion-Procedure-and-Programming-video.png';
import PRMediaToolkit from '../../assets/images/thumbnails/PR-Media-Toolkit.png';

import AssertIQ3Flyer from '../../assets/images/thumbnails/SS.5406355253.ICM.AssertIQ.DM5000.MAT-2313666v1.0.US.EXT.WR.png'
import AssertIQ3RepToPhysicianPresentation from '../../assets/images/thumbnails/PPT.5644914320.ICM.AssertIQ.DM5000.RepToPhysDeck.US.EXT.png'
import SymptomEpisodeSample from '../../assets/images/thumbnails/FL.5846391194.AssertIQ.SymptomEpisodeSample.MAT-2304017v2.0.US.EXT.WR.png'
import AssertIQ3SampleMerlinnetPCNPatientReport from '../../assets/images/thumbnails/FL.5846391194.AssertIQ.SymptomEpisodeSample.MAT-2304017v2.0.US.EXT.WR.png'
import AssertIQICMFlyer from '../../assets/images/thumbnails/Assert-IQ HCP Brochure MAT-2303144 v2.0.png'
import ChampDoc from '../../assets/images/thumbnails/Assert-IQ Champ Doc Addendum Memo MAT-2313985v1.0.png'
import OptimizingICMManagement from '../../assets/images/thumbnails/Merlin.net and ICM Online Training Program for AHPs Flyer MAT-2400823 v1.0.png'
import ICMComparisonGuideLINQ from '../../assets/images/thumbnails/ICM Comparison Guide - LINQ II Flyer MAT-2400609 v1.0.png'
import ICMComparisonGuideLUX from '../../assets/images/thumbnails/ICM Comparison Guide - Lux Dx II Flyer MAT-2400500 v1.0.png';

import NeuroHCPBrochureforICM from '../../assets/images/thumbnails/BR.7216184544.ICM.AFib.US.EXT.MAT-2200807v4.HR.png';
import ICMComparisonGuideBIOMONITOR from '../../assets/images/thumbnails/ICM.ComparisonGuide.Biotronik-MAT-2407935-v1.0.png'
class PatientResources extends Component {
	state = {
		searchText: '',
		sortingAsc: true,
		resources: [
			{
				title: ['Assert-IQ™ ICM HCP Brochure'],
				thumbnail: assertIQHCPBrochure,
				fileName: 'Assert-IQ-HCP-Brochure.pdf',
				type: 'pdf'
			},
			{
				title: ['Assert-IQ ICM Flyer'],
				thumbnail: AssertIQICMFlyer,
				fileName: 'Assert-IQ-HCP-Brochure-MAT-2303144-v2.0.pdf',
				type: 'pdf'
			},
			{
				title: ['Neuro HCP Brochure for ICM'],
				thumbnail: NeuroHCPBrochureforICM,
				fileName: 'BR.7216184544.ICM.AFib.US.EXT.MAT-2200807v4.HR.pdf',
				type: 'pdf'
			},
			{
				title: ['Champ Document'],
				thumbnail: champDocument,
				fileName: 'Champ-Document2.pdf',
				type: 'pdf'
			},
			{
				title: ['Champ Document Addendum'],
				thumbnail: ChampDoc,
				fileName: 'Assert-IQ-Champ-Doc-Addendum-Memo-MAT-2313985v1.0.pdf',
				type: 'pdf'
			},
			{
				title: ['Rep to Physician Presentation'],
				thumbnail: repToPhysicianPresentation,
				fileName: '05-Assert-IQ-Rep-to-Physician-Deck-MAT-2401995v1.pdf',
				type: 'pdf'
			},
			{
				title: ['Clinical Compendium'],
				thumbnail: assertIQClinicalCompendium,
				fileName: '06-Assert-IQ-Clinical-Compendium-MAT-2303062v3.pdf',
				type: 'pdf'
			},
			{
				title: ['Symptom Episode Sample'],
				thumbnail: SymptomEpisodeSample,
				fileName: 'FL.5846391194.AssertIQ.SymptomEpisodeSample.MAT-2304017v2.0.US.EXT.WR.pdf',
				type: 'pdf'
			},
			{
				title: ['Sample Merlin.net™ PCN Patient Report'],
				thumbnail: PCNPatientReport,
				fileName: 'SampleMerlin.net-PCN-Patient-Report.pdf',
				type: 'pdf'
			},
			{
				title: ['Optimizing ICM Management with Merlin.net™ PCN Webinars'],
				thumbnail: OptimizingICMManagement,
				fileName: 'Merlin.net-and-ICM-Online-Training-Program-for-AHPs-Flyer-MAT-2400823-v1.0.pdf',
				type: 'pdf'
			},



			{
				title: ['Assert-IQ ICM Catalog Page'],
				thumbnail: assertIQICMCatalogPage,
				fileName: 'AssertIQ-Catalog-Page-MAT-2401510v2.0.pdf',
				type: 'pdf'
			},
			{
				title: ['Introducing Assert-IQ ICM: The Latest Cardiac Monitoring Innovation'],
				thumbnail: IntroducingAssertIQICMTheLatestCardiacMonitoringInnovation,
				url: 'https://www.youtube.com/watch?v=D-Yx8xInjXc&list=PLLJGkrRsQxc3wYYPknx_T8AOVFl7zpqys&index=10',
				type: 'link'
			},
			{
				title: ['Assert-IQ ICM – Abbott’s latest innovation in cardiac monitoring'],
				thumbnail: AssertIQICMAbbottslatestinnovationincardiacmonitoring,
				url: 'https://www.youtube.com/watch?v=ZcGaKZRq1tQ&list=PLLJGkrRsQxc3wYYPknx_T8AOVFl7zpqys&index=1',
				type: 'link'
			},
			{
				title: ['Assert-IQ ICM Insertion Procedure and Programming video'],
				thumbnail: AssertIQICMInsertionProcedureandProgrammingvideo,
				url: 'https://www.youtube.com/watch?v=eSvc-bCl8_M&list=PLLJGkrRsQxc3wYYPknx_T8AOVFl7zpqys&index=2',
				type: 'link'
			},
			{
				title: ['ICM Comparison Guide – LINQ II‡'],
				thumbnail: ICMComparisonGuideLINQ,
				fileName: 'ICM-Comparison-Guide-LINQ-II-Flyer-MAT-2400609-v1.0.pdf',
				type: 'pdf'
			},
			{
				title: ['ICM Comparison Guide – LUX-Dx‡'],
				thumbnail: ICMComparisonGuideLUX,
				fileName: 'ICM.ComparisonGuide.LuxDxII-MAT-2400500-v2.0.pdf',
				type: 'pdf'
			},
			{
				title: ['ICM Comparison Guide - BIOMONITOR‡'],
				thumbnail: ICMComparisonGuideBIOMONITOR,
				fileName: 'ICM.ComparisonGuide.Biotronik-MAT-2407935-v1.0.pdf',
				type: 'pdf'
			},

			{
				title: ['PR Media Toolkit'],
				thumbnail: PRMediaToolkit,
				fileName: 'MediaToolKit.Update-MAT-2304917-v3.0.pdf',
				type: 'pdf'
			},

			/* {
				title: ['Merlin 3650 SW v27.0.1rev1 External Memo'],
				thumbnail: merlin3650SWv2701rev1ExternalMemo,
				fileName: '23-77455-Merlin-3650-SW-v27.0.1rev1-External-Memo1.pdf',
				type: 'pdf'
			}, */
			{
				title: ['Sample Assert-IQ 3 ICM Merlin.net PCN Patient Report'],
				thumbnail: AssertIQ3SampleMerlinnetPCNPatientReport,
				fileName: 'FL.5052867749.AssertIQ-DM5000.Merlin.netSampleReport.US.EXT.WR.pdf',
				type: 'pdf'
			},
			{
				title: ['Assert-IQ 3 ICM Rep to Physician Presentation'],
				thumbnail: AssertIQ3RepToPhysicianPresentation,
				fileName: 'PPT.5644914320.ICM.AssertIQ.DM5000.RepToPhysDeck.US.EXT.pdf',
				type: 'pdf'
			},
			{
				title: ['Assert-IQ 3 ICM Flyer'],
				thumbnail: AssertIQ3Flyer,
				fileName: 'SS.5406355253.ICM.AssertIQ.DM5000.MAT-2313666v1.0.US.EXT.WR.pdf',
				type: 'pdf'
			},
			{
				title: ['MRI-Ready Systems by Abbott'],
				thumbnail: mRIReadySystemsByAbbott,
				fileName: 'Abbott-MRI-Ready–Systems_US-MAT-2006170-v6.0.pdf',
				type: 'pdf'
			},

		],
		displayAsList: true
	};

	searchResources = (e) => {
		const searchText = e.target.value;
		this.setState({ searchText: searchText });
	};

	sortResources = () => {
		const sortingAsc = !this.state.sortingAsc;
		const resources = this.state.resources.sort(
			(a, b) =>
				sortingAsc
					? a.title < b.title ? -1 : a.title > b.title ? 1 : 0
					: b.title < a.title ? -1 : b.title > a.title ? 1 : 0
		);
		this.setState({ sortingAsc: sortingAsc, resources: resources });
	};

	render() {
		const { resources, searchText, sortingAsc, displayAsList } = this.state;
		const filteredResources = resources.filter((rsrc) =>
			rsrc.title.toString().toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
		);
		return (
			<div className="page">
				<div className="sectionBreadcrumb">
					<Link className="pointer" to="/resource-libraries">
						<i className="las la-angle-left" /> RESOURCE LIBRARIES
					</Link>
				</div>
				<Container fluid="xl" className="withBreadcrumb">
					<Row className=" sectionBanner purpleGradient" style={{ position: 'relative' }} >

						<div style={{ position: 'absolute', right: 0, maxHeight: '100%', display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
							<img src={ProductImage} style={{ width: '100%', height: '100%' }} />
						</div>
						<Col>
							<h4 className='white' style={{ margin: '50px 0' }} >HCP<br />RESOURCES</h4>
						</Col>
					</Row>

					<Row className="search-filter-row">
						<Col xs={12}>
							<InputGroup>
								<InputGroupAddon addonType="prepend">
									<InputGroupText className="inputGroupText">
										<i className="las la-search appPrimaryColor" />
									</InputGroupText>
								</InputGroupAddon>
								<Input
									placeholder="Search HCP Resources..."
									value={searchText}
									onChange={this.searchResources}
									className="inputSearch"
								/>
							</InputGroup>
							<div className="filter-section">
								<div className="alpha-sort">
									Sort By: <span onClick={this.sortResources}>{sortingAsc ? 'A - Z' : 'Z - A'}</span>
								</div>
								<div className="filter-view-section">
									<div
										className={displayAsList ? 'list-view-active' : 'list-view-inactive'}
										onClick={() => this.setState({ displayAsList: true })}
									>
										<i className="las la-th-list" />
									</div>

									<div
										className={!displayAsList ? 'list-view-active' : 'list-view-inactive'}
										onClick={() => this.setState({ displayAsList: false })}
									>
										<i className="las la-th-large" />
									</div>
								</div>
							</div>
						</Col>
					</Row>
					<br />
					<Row>
						<Col xs={12} />
					</Row>
					<Row>
						{displayAsList ? (
							filteredResources.map(
								(resource) =>
									resource.type === 'pdf' ? (
										<PDFListItem resource={resource} />
									) : (
										resource.type === 'link' && <LinkListItem resource={resource} />
									)
							)
						) : (
							filteredResources.map(
								(resource) =>
									resource.type === 'pdf' ? (
										<PDFTileItem resource={resource} />
									) : (
										resource.type === 'link' && <LinkTileItem resource={resource} />
									)
							)
						)}
						{filteredResources.length <= 0 && (
							<Col xs={12}>
								<p style={{ textAlign: 'center' }}>No results for "{searchText}"</p>
							</Col>
						)}
					</Row>

					<Row style={{ paddingTop: 40, paddingBottom: 40, marginTop: 'auto' }}>
						<Col xs={12}>
							<div
								style={{
									width: '100%',
									padding: '0 20px',
									paddingBottom: 10
								}}
							/>
							<div className="footNote">
								<p >
									© 2024 Abbott. All Rights Reserved.<br />
									MAT-2304397 v6.0 | Item approved for US use only.
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

class PDFListItem extends Component {
	render() {
		const { resource } = this.props;
		return (
			<Col xs={12}>
				<Link
					to={`/resource-libraries/pdf-viewer?title=${resource.title
						.map((t) => (t instanceof Object ? 'TM' : t))
						.join('')}&file=${resource.fileName}`}
				>
					<div className="list-item">
						<img src={resource.thumbnail} />
						<div className="list-item-title">{resource.title}</div>

						<i className="las la-angle-right" />
					</div>
				</Link>
			</Col>
		);
	}
}

class LinkListItem extends Component {
	render() {
		const { resource } = this.props;
		return (
			<Col xs={12}>
				<a href={`${resource.url}`} target="_blank" rel="noopener noreferrer">
					<div className="list-item">
						<img src={resource.thumbnail} />
						<div className="list-item-title">{resource.title}</div>

						<i className="las la-angle-right" />
					</div>
				</a>
			</Col>
		);
	}
}

class PDFTileItem extends Component {
	render() {
		const { resource } = this.props;
		return (
			<Col xs={6} lg={3}>
				<Link to={`/resource-libraries/pdf-viewer?title=${resource.title}&file=${resource.fileName}`}>
					<img src={resource.thumbnail} className="tileImage" />
					<div className="tile-item-title">{resource.title}</div>
				</Link>
			</Col>
		);
	}
}

class LinkTileItem extends Component {
	render() {
		const { resource } = this.props;
		return (
			<Col xs={6} lg={3}>
				<a href={`${resource.url}`}>
					<img src={resource.thumbnail} className="tileImage" />
					<div className="tile-item-title">{resource.title}</div>
				</a>
			</Col>
		);
	}
}

export default withRouter(PatientResources);
